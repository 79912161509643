var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Dropdown Tree", "code-toggler": "" } },
    [
      _c("v-select-tree", {
        attrs: { data: _vm.treeData, multiple: true },
        model: {
          value: _vm.initSelected,
          callback: function ($$v) {
            _vm.initSelected = $$v
          },
          expression: "initSelected",
        },
      }),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <v-select-tree :data='treeData' v-model='initSelected' :multiple=\"true\"/>\n</template>\n\n<script>\nimport { VTree, VSelectTree}  from 'vue-tree-halower'\n\nexport default {\n  data() {\n    return {\n      initSelected: ['node-1'],\n      treeData: [{\n        title: 'node1',\n        expanded: true,\n        children: [{\n          title: 'node 1-1',\n          expanded: true,\n          children: [{\n            title: 'node 1-1-1'\n          }, {\n            title: 'node 1-1-2'\n          }, {\n            title: 'node 1-1-3'\n          }]\n        }]\n      }]\n    }\n  },\n  components: {\n    VTree,\n    VSelectTree\n  }\n}\n</script>\n\n<style lang=\"scss\">\n@import \"@/assets/scss/vuexy/extraComponents/tree.scss\";\n\n.tree-box{\n  background: #fff;\n  position: relative;\n  z-index: 9;\n\n  .search-input {\n    margin-top: 10px;\n    width: 98%;\n    display: block;\n  }\n}\n\n.rmNode{\n  background-color: rgba(var(--vs-danger),0.15);\n  color: rgba(var(--vs-danger),1);\n  line-height: 13px;\n}\n</style>\n    "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }