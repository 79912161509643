var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Async Tree", "code-toggler": "" } },
    [
      _c("v-tree", {
        ref: "tree",
        attrs: { data: _vm.treeData, multiple: false },
        on: {
          "node-check": _vm.nodechecked,
          "async-load-nodes": _vm.asyncLoad,
        },
      }),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<tempalte>\n  <v-tree ref=\"tree\" :data='treeData' :multiple='false' @node-check='nodechecked' @async-load-nodes='asyncLoad'/>\n</tempalte>\n\n<script>\nimport { VTree, VSelectTree}  from 'vue-tree-halower'\n\nexport default {\n  data() {\n    return {\n      initSelected: ['node-1'],\n      treeData: [{\n        title: 'node1',\n        expanded: false,\n        async: true\n      }]\n    }\n  },\n  methods: {\n    nodechecked (node, v) {\n      alert('that a node-check envent ...' + node.title + v)\n    },\n    async asyncLoad (node) {\n      const {checked = false} = node\n      this.$set(node, 'loading', true)\n      let pro = await new Promise(resolve => {\n        setTimeout(resolve, 2000, [{title: 'test1', async: true}, {title: 'test2', async: true}, {title: 'test3'}])\n      })\n      if (!node.hasOwnProperty('children')) {\n        this.$set(node, 'children', [])\n      }\n      node.children.push(...pro)\n      this.$set(node, 'loading', false)\n      if (checked) {\n        this.$refs.tree.childCheckedHandle(node, checked)\n      }\n    },\n  },\n  components: {\n    VTree,\n    VSelectTree\n  }\n}\n</script>\n    "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }